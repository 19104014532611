// import { mapState } from "vuex";
// import EventBus from "@/eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js";
import global from "@/method/globalMethod.js";
import { getQueryMark } from "@/administration/upmarker.js";
import m0 from "@/assets/map/m0.png";
import m1 from "@/assets/map/m1.png";
import m2 from "@/assets/map/m2.png";
import m3 from "@/assets/map/m3.png";
const markerusers = {
    data() {
        return {
            markers: [],    //点聚合数组
            gooleCover: [],  //谷歌覆盖物数组
            googleMarkerClusterer: null,  //谷歌地图聚合
            recordMark: [],
            recordMarker: "",  //百度地图Marker记录点击的定位点
            markerUser: {},
            markerUserOverlapId: 0,
            mark: {},    //标记点
            markList: []  //标记点
        }
    },
    beforeDestroy() {
        window.removeEventListener('click', this.clickShowUserInfo);
    },
    mounted() {
        window.expand = this.expand;
        window.overMap = this.overMap;
        window.outMap = this.outMap;
        window.clickShowUserInfo = this.clickShowUserInfo;
        window.closeLabel = this.closeLabel;
        // 监听全局鼠标点击事件
        window.addEventListener("click", this.clickShowUserInfo);
    },
    methods: {
        /*创建定位点*/
        setMarker(arr) {
            let item = arr[0];
            item.pot.Lng = global.JudgingHemisphere(item.pot).Lng;
            item.pot.Lat = global.JudgingHemisphere(item.pot).Lat;
            let isLabel = this.$store.state.recordLable.includes(item.id);  //判断当前label是否有关闭
            let isExceed = item.isExceed;  //判断是否超过五分钟
            let bool = this.isShow && !isLabel;  //是否展示label
            var html = "";
            /*自定义图标*/
            let imgUrl = "@/assets/map/djj.png";
            if (item.online != 3) {
                imgUrl = require("@/assets/map/djj.png");
            } else {
                imgUrl = isExceed ? require("@/assets/map/djj2.png") : require("@/assets/map/djj1.png");
            }
            item.imgUrl = item.img ? `${beasconfig.xinextendUrlapi}/icon${item.img}` : imgUrl;
            if (arr.length > 1) {
                html = global.multipleMapHtml(2, item, arr, this.mapType);
            } else {
                html = global.mapHtml(item, this.mapType, bool);
            }

            if (this.gooleWc) {
                var position = new google.maps.LatLng(item.pot.Lat, item.pot.Lng);
                const content = document.createElement("div");
                content.innerHTML = html;
                const markerUser = new google.maps.marker.AdvancedMarkerElement({
                    map: this.maps,
                    content: content,
                    position: position,
                    // title: '',
                });
                markerUser.id = item.id;
                markerUser.zIndex = item.czindex;
                markerUser.czindex = item.czindex;
                markerUser.row = item;
                markerUser.arr = arr;
                markerUser.addListener('click', () => {
                    this.markerUser = item;
                    this.dialogModifyMarker = true;
                    this.recordMarker = markerUser;
                    this.markerZindex(markerUser.id);
                });
                this.gooleCover.push(markerUser);
                if(this.googleMarkerClusterer){
                    this.googleMarkerClusterer.addMarker(markerUser);
                }
            }
        },
        markerZindex(id) {
            if (this.mapType == 'Wgs84') {
                this.gooleCover.forEach(marker => {
                    if (marker.id == id) {   //判断是否为当前markerLabel 如果是则将优先级设为最高
                        marker.zIndex = 9999
                    } else {
                        marker.zIndex = marker.czindex
                    }
                })
            }
        },
        showMarkerLabel(id, type) { //显示lable时间
            var t = document.getElementById(`rtime${id}`);//选取label为百度baidu的id进行隐藏
            var labelId = document.getElementById(`rtimelabel${id}`);
            if (labelId) labelId.style.display = type == 1 ? 'none' : 'block';// 以块级样式隐藏
            if (t) {
                if (type == 1) {
                    t.style.display = 'block';// 以块级样式隐藏
                } else {
                    t.style.display = 'none';// 以块级样式隐藏
                }
            }
        },
        expand(id, type) {  //展开用户信息
            var z = document.getElementById(`expand2${id}`);//展开
            var elements = document.querySelectorAll(`#expand1${id}`);
            elements.forEach(function (element) {
                element.style.display = type == 2 ? 'block' : 'none';// 以块级样式隐藏
            });
            z.style.display = type == 2 ? 'none' : 'block';// 以块级样式隐藏
        },
        overMap(id) {  //谷歌地图
            this.markerZindex(id);
            this.showMarkerLabel(id, 1)
        },
        outMap(id) {
            this.showMarkerLabel(id, 0)
        },
        clickShowUserInfo(id, zid, event) {  //展示用户信息
            // zid 是父盒子的id
            if (id && zid) {  //判断是点击的用户
                this.markerUserOverlapId = zid;
                let m = this.gooleCover.find(item => item.row.id == zid);
                let row = m.arr.find(item => item.id == id);
                var t = document.getElementById(`userMarker${zid}`);//选取label为百度baidu的id进行隐藏
                t.style.display = 'block';// 以块级样式隐藏
                t.innerHTML = global.multipleMapHtml(1, row);
                event.preventDefault();
                // 阻止事件冒泡 
                event.stopPropagation();
            } else {
                if (this.markerUserOverlapId > 0) {
                    var t = document.getElementById(`userMarker${this.markerUserOverlapId}`);//选取label为百度baidu的id进行隐藏
                    if (t) {
                        t.style.display = 'none';// 以块级样式隐藏
                        this.markerUserOverlapId = 0;
                    }
                }
            }
        },
        closeLabel(id, type, event) {  //移除label
            if (type == 1) {  //1是移除label
                var t = document.getElementById(`baidu${id}`);//选取label为百度baidu的id进行隐藏
                t.style.display = 'none';// 以块级样式隐藏
                t.remove();
                this.$store.state.recordLable.push(id);
            } else if (type == 2) {  //2是移除测距功能
                this.rangeCompletoArr[id].line.setMap(null);
                this.rangeCompletoArr[id].marker.forEach(item => {  //清空marker
                    item.setMap(null)
                })
                delete this.rangeCompletoArr[id];  //删除当前的对象值
            }
            event.preventDefault();
            // 阻止事件冒泡
            event.stopPropagation();
        },
        removeMarkers() {    //删除点聚合
            if (this.gooleCover.length > 0) {
                if(this.googleMarkerClusterer){
                    this.googleMarkerClusterer.removeMarkers(this.gooleCover);
                }
                this.gooleCover.forEach(item => item.setMap(null));
                this.gooleCover = [];
            }
        },
        // 根据点的数组自动调整缩放级别
        setZoom(bPoints, type) {
            if (type == 1) {

                return
            }
            if (this.mapType == 'Wgs84') {
                if (bPoints.length > 0) {
                    var latlngbounds = new google.maps.LatLngBounds();  //根据点的数组自动调整缩放级别
                    bPoints.forEach(item => {
                        let lat = item.pot ? item.pot.Lat : item.lat;
                        let lng = item.pot ? item.pot.Lng : item.lng;
                        latlngbounds.extend(new google.maps.LatLng(lat, lng));
                    })
                    this.maps.fitBounds(latlngbounds);
                    this.maps.panToBounds(latlngbounds);
                }
            }
        },
        successMark() {  //成功修改自定义照片
            this.getMarkList();
            this.dialogModifyMark = false;
        },
        cloneMark() {  //关闭自定义图片弹出框
            this.getMarkList();
            this.dialogModifyMark = false;
        },
        setMarkImg(item) {  //设置图标
            /*自定义图标*/
            let imgUrl = item.img ? `${beasconfig.xinextendUrlapi}/icon${item.img}` : require("@/assets/map/mark.png");
            if (this.mapType == 'Wgs84') {
                let marker = new google.maps.Marker({
                    position: { lat: Number(item.lat), lng: Number(item.lng) },
                    map: this.maps,
                    // title: '自定义标注',
                    icon: imgUrl // 设置自定义图标
                });
                marker.addListener('click', () => {
                    this.mark = item;
                    this.dialogModifyMark = true;
                });
                this.recordMark.push(marker);
            }
        },
        successMarker(item) {  //成功修改自定义照片
            let imgUrl = "@/assets/map/djj.png";
            let bool = item.online == 3 ? this.InspectionTime(item.pot.Rtime) : false;
            if (item.online != 3) {
                imgUrl = require("@/assets/map/djj.png");
            } else {
                imgUrl = bool ? require("@/assets/map/djj2.png") : require("@/assets/map/djj1.png");
            }
            imgUrl = item.img ? `${beasconfig.xinextendUrlapi}/icon${item.img}` : imgUrl;
            if (this.mapType == 'Wgs84') {
                document.getElementById(`mkuserimg${item.id}`).src = imgUrl;
            }
            this.dialogModifyMarker = false;
        },
        cloneMarker() {  //关闭自定义图片弹出框
            this.dialogModifyMarker = false;
        },
        getMarkList() {
            this.recordMark.forEach(item => {
                item.setMap(null);
            })
            this.recordMark = [];
            getQueryMark({
                orgId: this.$store.state.userInfo.org_id
            }).then(res => {
                let markList = res.data.data;
                if (markList.length > 0) {
                    markList.forEach(item => {
                        this.setMarkImg(item)
                    })
                    this.markList = markList;
                } else {
                    this.markList = [];
                }
            })
        },
        roundPng(num) {  //圆形图标
            let url = m0;
            if (num <= 500) {
                url = m0;
            } else if (num >= 500 && num <= 1000) {
                url = m1;
            } else if (num > 1000 && num <= 2000) {
                url = m2;
            } else {
                url = m3;
            }
            return url;
        },
    },
}

export { markerusers };